<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input
            v-model="query.login"
            clearable
            placeholder="MT4账户"
            style="width: 150px;"
            @mousewheel.native.prevent onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            type='number'
            class="filter-item" @keyup.enter.native="toQuery"
    />
    <el-input
            v-model="query.symbol"
            clearable
            placeholder="交易品种"
            style="width: 150px;"
            class="filter-item"
            @keyup.enter.native="toQuery"
    />
     <div class="filter-item">
      平仓时间
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束时间">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button
            type="primary"
            class="filter-item" size="mini" t icon="el-icon-close" @click="clear">清空</el-button>
    <el-button
            v-if="checkPermission(['EXPORT_TRADE_STATISTICS','ADMIN'])"
            :loading="loading"
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="exportExecl"
    >导出</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  created() {
  },
  data(){
    return {
      loading:false
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear() {
      this.$emit('clear');
    },
    exportExecl(){
      this.loading=true
      this.$emit('export-execl');
    }
  }
}
</script>
