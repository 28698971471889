<template>
  <div class="app-container summary-page traderecord-page">
    <cent-tab
        ref="centtab"
        @send-type="sendType"
    ></cent-tab>
    <eHeader
      ref='header'
      :query="query"
      @export-execl="exportExecl"
      @clear="clear"
      @toQuery="toQuery"
    />
    <blockquote class="my-blockquote statistics-box">
      <div class="statistics-item">
        总手数:{{statistics.totalVolume}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        Forex: {{statistics.volumeMap.Forex}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        Gold: {{statistics.volumeMap.GOLD}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        siver: {{statistics.volumeMap.SIVLER}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        Oil: {{statistics.volumeMap.Oil}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        Index: {{statistics.volumeMap.INDEX}}手
      </div>
<!--把Stock统计上,单位也要联调上-->
      <div class="statistics-item">
        Stock: {{statistics.volumeMap.Stock}}手
      </div>
    </blockquote>
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="rank" label="序号" width="50"/>
      <el-table-column prop="orderId" label="订单号"/>
      <el-table-column prop="login" label="MT账户"/>
      <el-table-column prop="symbol" label="交易产品"/>
      <el-table-column prop="cmd" label="交易类型">
        <template slot-scope="scope">
          <span>{{scope.row.cmd == 0 ? '买' : '卖'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="volume" label="手数"/>
      <el-table-column prop="openPrice" label="开仓价格"/>
      <el-table-column prop="openTime" label="开仓时间" >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.openTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="closePrice" label="平仓价格"/>
      <el-table-column prop="closeTime" label="平仓时间" >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.closeTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="storage" label="隔夜利息"/>
      <el-table-column prop="commission" label="手续费"/>
      <el-table-column prop="profit" label="获利"/>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { initData } from '@/api/data'
import { parseTime } from '@/utils/index'
import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
import eHeader from '@/components/stat/traderecord/header'
export default {
  name:'traderecord',
  components: { eHeader },
  mixins: [initDataComm],
  props:{
  },
  data() {
    return {
      loading: false,
      sup_this: this,
      statistics:{
        totalVolume: '',
        totalStorage: '',
        totalCommission: '',
        totalProfit: '',
        volumeMap: {
          Forex: '',
          GOLD: '',
          SIVLER: '',
          Oil: '',
          INDEX: '',
        }
      },
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  updated(){
    this.$nextTick(() => {
      this.setSumProfitColor();
    })
  },
  methods: {
    parseTime,
    checkPermission,
    export_json_to_excel,
    formatJson,
    initData,
    sendType(){
      this.page = 0;
      this.init();
    },
    beforeInit() {
      this.url = '/crm/queryStatisticsOrder'
      this.params = {
        page: this.page,
        size: this.size,
        currency: this.$refs.centtab.type,
      }
      const query = this.query
      const login = query.login
      const symbol = query.symbol
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)
      if (login!=null && login!='') { this.params['login'] = login }
      if (symbol!=null && symbol!='') { this.params['symbol'] = symbol }
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      return true
    },
    clear(){
      this.query = {};
      this.toQuery();
    },
    exportExecl(){
      const query = this.query
      const login = query.login
      const symbol = query.symbol
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)
      if (login!=null && login!='') { this.params['login'] = login }
      if (symbol!=null && symbol!='') { this.params['symbol'] = symbol }
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      this.params.export = true;
      this.initData('/crm/queryStatisticsOrder', this.params).then(res => {
        this.$refs['header'].loading =false
        const tHeader =['订单号', 'MT4账户','交易产品','交易类型','手数','开仓价格', '开仓时间','平仓价格','平仓时间','隔夜利息($)', '手续费($)','获利($)'];
        const filterVal =[ 'orderId', 'login','symbol','cmd','volume','openPrice', 'openTime','closePrice','closeTime','storage','commission','profit'];
        const exportData = formatJson(filterVal,res.content)
        let fileName='交易明细报表'
        const query = this.query
        const login = query.login
        const symbol = query.symbol
        const startTime = this.parseTime(query.startTime)
        const endTime = this.parseTime(query.endTime)
        if (login!=null && login!='') { fileName = fileName+'-'+login }
        if (symbol!=null && symbol!='') { fileName = fileName+'-'+symbol }
        if (startTime!=null && startTime!='') { fileName = fileName+'-'+this.parseTime(query.startTime) }
        if (endTime!=null && endTime!='') { fileName = fileName+'-'+this.parseTime(query.endTime) }
        export_json_to_excel(tHeader,exportData,fileName);
      }).catch(err => {
        this.$refs['header'].loading =false
      })
    },
    setSumProfitColor(){
      const s_table = document.getElementsByClassName('el-table__footer-wrapper')[0]
      const child_tr_childivs = s_table.getElementsByTagName('tr')[0].childNodes;
      child_tr_childivs.forEach((item,index) => {
        if(index == 10 || index == 12){
          let divEle = item.getElementsByClassName('cell')[0];
          if(divEle.innerText.indexOf('-') > -1){
            divEle.setAttribute('style', 'color: #E74B3A');
          }else {
            divEle.setAttribute('style', 'color: #11AC80');
          }
        }
      })
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 10:
            sums[index] = `${this.statistics.totalStorage}`;
            break;
          case 11:
            sums[index] = `${this.statistics.totalCommission}`;
            break;
          case 12:
            sums[index] = `${this.statistics.totalProfit}`;
            break;
        }
      });

      return sums;
    },
  }
}
</script>

<style lang="less">
  .traderecord-page {
      .el-table {
          .el-table__footer-wrapper {
              .has-gutter {
                  tr {
                      .is-leaf:nth-of-type(6) {
                          .cell {
                              white-space: pre-wrap !important;
                          }
                      }
                  }
              }
          }
      }
  }
</style>
